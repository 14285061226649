import gpAbTestingTabIcon from './icons/ABTestingTab.vue';
import gpAlignBottomIcon from './icons/AlignBottom.vue';
import gpAlignCenterIcon from './icons/AlignCenter.vue';
import gpAlignCenterVerticalIcon from './icons/AlignCenterVertical.vue';
import gpAlignLeftIcon from './icons/AlignLeft.vue';
import gpAlignRightIcon from './icons/AlignRight.vue';
import gpAlignSpaceBetweenIcon from './icons/AlignSpaceBetween.vue';
import gpAlignTopIcon from './icons/AlignTop.vue';
import gpBgColorBlueIcon from './icons/BgColorBlue.vue';
import gpBgColorGreenIcon from './icons/BgColorGreen.vue';
import gpBgColorRedIcon from './icons/BgColorRed.vue';
import gpBgColorYellowIcon from './icons/BgColorYellow.vue';
import gpBgRepeatIcon from './icons/BgRepeat.vue';
import gpBgRepeatXIcon from './icons/BgRepeatX.vue';
import gpBgRepeatYIcon from './icons/BgRepeatY.vue';
import gpBgTransparentIcon from './icons/BgTransparent.vue';
import gpBorderBottomIcon from './icons/BorderBottom.vue';
import gpBorderFullIcon from './icons/BorderFull.vue';
import gpBorderLeftIcon from './icons/BorderLeft.vue';
import gpBorderRightIcon from './icons/BorderRight.vue';
import gpBorderSeparatedIcon from './icons/BorderSeparated.vue';
import gpBorderTopIcon from './icons/BorderTop.vue';
import gpColorBlackSquareIcon from './icons/ColorBlackSquare.vue';
import gpColorGreenSquareIcon from './icons/ColorGreenSquare.vue';
import gpColorRedSquareIcon from './icons/ColorRedSquare.vue';
import gpColorYellowSquareIcon from './icons/ColorYellowSquare.vue';
import gpCornerPillIcon from './icons/CornerPill.vue';
import gpCornerRoundedIcon from './icons/CornerRounded.vue';
import gpCornerSingleIcon from './icons/CornerSingle.vue';
import gpCornerSquareIcon from './icons/CornerSquare.vue';
import gpCreatedDateConditionIcon from './icons/CreatedDateCondition.vue';
import gpCursorNoneIcon from './icons/CursorNone.vue';
import gpCustomizeIcon from './icons/Customize.vue';
import gpDashIcon from './icons/Dash.vue';
import gpDiscountConditionIcon from './icons/DiscountCondition.vue';
import gpDotsIcon from './icons/Dots.vue';
import gpGemXIcon from './icons/GemX.vue';
import gpImageShapeHorizontalIcon from './icons/ImageShapeHorizontal.vue';
import gpImageShapeSquareIcon from './icons/ImageShapeSquare.vue';
import gpImageShapeVerticalIcon from './icons/ImageShapeVertical.vue';
import gpInventoryConditionIcon from './icons/InventoryCondition.vue';
import gpJustifyContentBottomIcon from './icons/JustifyContentBottom.vue';
import gpJustifyContentCenterIcon from './icons/JustifyContentCenter.vue';
import gpJustifyContentLeftIcon from './icons/JustifyContentLeft.vue';
import gpJustifyContentMiddleIcon from './icons/JustifyContentMiddle.vue';
import gpJustifyContentRightIcon from './icons/JustifyContentRight.vue';
import gpJustifyContentSpaceBetweenIcon from './icons/JustifyContentSpaceBetween.vue';
import gpJustifyContentTopIcon from './icons/JustifyContentTop.vue';
import gpLineIcon from './icons/Line.vue';
import gpLineDashedIcon from './icons/LineDashed.vue';
import gpLineDottedIcon from './icons/LineDotted.vue';
import gpLineDoubleIcon from './icons/LineDouble.vue';
import gpLineSolidIcon from './icons/LineSolid.vue';
import gpLinkIcon from './icons/Link.vue';
import gpLoadingIcon from './icons/Loading.vue';
import gpPaddingIcon from './icons/Padding.vue';
import gpPaddingLinkedIcon from './icons/PaddingLinked.vue';
import gpPaddingSeparateIcon from './icons/PaddingSeparate.vue';
import gpPaddingSeparatorIcon from './icons/PaddingSeparator.vue';
import gpPositionBottomIcon from './icons/PositionBottom.vue';
import gpPositionBottomLeftIcon from './icons/PositionBottomLeft.vue';
import gpPositionBottomRightIcon from './icons/PositionBottomRight.vue';
import gpPositionCenterIcon from './icons/PositionCenter.vue';
import gpPositionLeftIcon from './icons/PositionLeft.vue';
import gpPositionRightIcon from './icons/PositionRight.vue';
import gpPositionTopIcon from './icons/PositionTop.vue';
import gpPositionTopLeftIcon from './icons/PositionTopLeft.vue';
import gpPositionTopRightIcon from './icons/PositionTopRight.vue';
import gpPreviewMajorIcon from './icons/PreviewMajor.vue';
import gpPriceRangeConditionIcon from './icons/PriceRangeCondition.vue';
import gpProductTagConditionIcon from './icons/ProductTagCondition.vue';
import gpQuestion18Icon from './icons/Question18.vue';
import gpShadowHardIcon from './icons/ShadowHard.vue';
import gpShadowLightIcon from './icons/ShadowLight.vue';
import gpShadowMediumIcon from './icons/ShadowMedium.vue';
import gpStarBoldIcon from './icons/StarBold.vue';
import gpStarFilledIcon from './icons/StarFilled.vue';
import gpStarLightIcon from './icons/StarLight.vue';
import gpStarRegularIcon from './icons/StarRegular.vue';
import gpTextAlignJustifyIcon from './icons/TextAlignJustify.vue';
import gpUnlinkIcon from './icons/Unlink.vue';
import gpUploadIcon from './icons/Upload.vue';

export const icons = {
  'gp-ab-testing-tab': gpAbTestingTabIcon,
  'gp-align-bottom': gpAlignBottomIcon,
  'gp-align-center': gpAlignCenterIcon,
  'gp-align-center-vertical': gpAlignCenterVerticalIcon,
  'gp-align-left': gpAlignLeftIcon,
  'gp-align-right': gpAlignRightIcon,
  'gp-align-space-between': gpAlignSpaceBetweenIcon,
  'gp-align-top': gpAlignTopIcon,
  'gp-bg-color-blue': gpBgColorBlueIcon,
  'gp-bg-color-green': gpBgColorGreenIcon,
  'gp-bg-color-red': gpBgColorRedIcon,
  'gp-bg-color-yellow': gpBgColorYellowIcon,
  'gp-bg-repeat': gpBgRepeatIcon,
  'gp-bg-repeat-x': gpBgRepeatXIcon,
  'gp-bg-repeat-y': gpBgRepeatYIcon,
  'gp-bg-transparent': gpBgTransparentIcon,
  'gp-border-bottom': gpBorderBottomIcon,
  'gp-border-full': gpBorderFullIcon,
  'gp-border-left': gpBorderLeftIcon,
  'gp-border-right': gpBorderRightIcon,
  'gp-border-separated': gpBorderSeparatedIcon,
  'gp-border-top': gpBorderTopIcon,
  'gp-color-black-square': gpColorBlackSquareIcon,
  'gp-color-green-square': gpColorGreenSquareIcon,
  'gp-color-red-square': gpColorRedSquareIcon,
  'gp-color-yellow-square': gpColorYellowSquareIcon,
  'gp-corner-pill': gpCornerPillIcon,
  'gp-corner-rounded': gpCornerRoundedIcon,
  'gp-corner-single': gpCornerSingleIcon,
  'gp-corner-square': gpCornerSquareIcon,
  'gp-created-date-condition': gpCreatedDateConditionIcon,
  'gp-cursor-none': gpCursorNoneIcon,
  'gp-customize': gpCustomizeIcon,
  'gp-dash': gpDashIcon,
  'gp-discount-condition': gpDiscountConditionIcon,
  'gp-dots': gpDotsIcon,
  'gp-gem-x': gpGemXIcon,
  'gp-image-shape-horizontal': gpImageShapeHorizontalIcon,
  'gp-image-shape-square': gpImageShapeSquareIcon,
  'gp-image-shape-vertical': gpImageShapeVerticalIcon,
  'gp-inventory-condition': gpInventoryConditionIcon,
  'gp-justify-content-bottom': gpJustifyContentBottomIcon,
  'gp-justify-content-center': gpJustifyContentCenterIcon,
  'gp-justify-content-left': gpJustifyContentLeftIcon,
  'gp-justify-content-middle': gpJustifyContentMiddleIcon,
  'gp-justify-content-right': gpJustifyContentRightIcon,
  'gp-justify-content-space-between': gpJustifyContentSpaceBetweenIcon,
  'gp-justify-content-top': gpJustifyContentTopIcon,
  'gp-line': gpLineIcon,
  'gp-line-dashed': gpLineDashedIcon,
  'gp-line-dotted': gpLineDottedIcon,
  'gp-line-double': gpLineDoubleIcon,
  'gp-line-solid': gpLineSolidIcon,
  'gp-link': gpLinkIcon,
  'gp-loading': gpLoadingIcon,
  'gp-padding': gpPaddingIcon,
  'gp-padding-linked': gpPaddingLinkedIcon,
  'gp-padding-separate': gpPaddingSeparateIcon,
  'gp-padding-separator': gpPaddingSeparatorIcon,
  'gp-position-bottom': gpPositionBottomIcon,
  'gp-position-bottom-left': gpPositionBottomLeftIcon,
  'gp-position-bottom-right': gpPositionBottomRightIcon,
  'gp-position-center': gpPositionCenterIcon,
  'gp-position-left': gpPositionLeftIcon,
  'gp-position-right': gpPositionRightIcon,
  'gp-position-top': gpPositionTopIcon,
  'gp-position-top-left': gpPositionTopLeftIcon,
  'gp-position-top-right': gpPositionTopRightIcon,
  'gp-preview-major': gpPreviewMajorIcon,
  'gp-price-range-condition': gpPriceRangeConditionIcon,
  'gp-product-tag-condition': gpProductTagConditionIcon,
  'gp-question-18': gpQuestion18Icon,
  'gp-shadow-hard': gpShadowHardIcon,
  'gp-shadow-light': gpShadowLightIcon,
  'gp-shadow-medium': gpShadowMediumIcon,
  'gp-star-bold': gpStarBoldIcon,
  'gp-star-filled': gpStarFilledIcon,
  'gp-star-light': gpStarLightIcon,
  'gp-star-regular': gpStarRegularIcon,
  'gp-text-align-justify': gpTextAlignJustifyIcon,
  'gp-unlink': gpUnlinkIcon,
  'gp-upload': gpUploadIcon,
};
