<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import type { LangKey } from '@gem/element-setting-ui';
import { GSelect, OptionSelect } from '../../index';
import useEditorStore from 'gemx/src/modules/editor/modules/common/stores/editor';

type LanguageOptions = {
  id: LangKey;
  value: LangKey;
  name: string;
};

const editorStore = useEditorStore();

const LOCAL_STORAGE_KEY = 'lang';

const activeLang = ref<string>('en');

const options: LanguageOptions[] = [
  { id: 'en', value: 'en', name: 'English' },
  { id: 'cn', value: 'cn', name: '汉语' },
  { id: 'es', value: 'es', name: 'Español' },
];

onMounted(() => {
  activeLang.value = localStorage.getItem(LOCAL_STORAGE_KEY) || 'en';
});

const handleChangeLanguage = (lang: LangKey) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, lang);
  // TODO: save
  window.location.reload();
};
</script>

<template>
  <div class="rounded-medium text-dark-400 w-full bg-white p-16">
    <div class="flex items-end justify-between">
      <span class="font-medium">Language Setting</span>
    </div>
    <div class="mt-8">
      <GSelect
        :is-light-mode="true"
        :options="options as OptionSelect[]"
        :active-id="activeLang"
        @select="handleChangeLanguage">
        <template #button-content>
          {{ options.find((item) => item.value === activeLang).name }}
        </template>
      </GSelect>
    </div>
  </div>
</template>
